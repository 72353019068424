import React from 'react'

export default function Footer({className ="", simple=false}) {
    if (simple) {
        return <div className={" bg-gray-700 text-gray-200 pb-1 pt-1 flex flex-grow justify-center items-center w-full text-xs "}>  Made with Rotation.Degesh.com (Konstantin Davidenko)</div>
    }
  return (
    <>
        <div className={'h-6 ' + className}></div>
        <div className={"fixed bottom-0 bg-gray-700 text-gray-200 pb-1 pt-1 flex flex-grow justify-center items-center w-full text-xs " + className}>Made by Konstantin Davidenko </div>
    </>
  )
}
