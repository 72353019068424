import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { EditText, EditTextarea } from 'react-edit-text';

export default function GroupListItem({item ={}, className, deleteCallback, editCallback}) {
    const handleSaveName = ({ name, value, previousValue }) => {
        editCallback({item,name:value});
    };
    // console.log(item)
  return (
    <div className={`border-2  border-gray-300  flex  ${className}`}>
        <div className='flex-grow pl-o' >
            <div className='font-bold'>Name</div>
            <EditText
                name="textbox1"
                defaultValue={item.name}
                className='h-6'
                inputClassName=''
                onSave={handleSaveName}
            />
        </div>
        
        <button 
            className='bg-gray-400 hover:bg-gray-600  text-gray-200 p-2'
            onClick={deleteCallback}
        >
            <FontAwesomeIcon icon={faTrash} />
        </button>
    </div>
  )
}
