import React, {useEffect, useState} from 'react'

export default function RotationResultStation({station, rotation}) {
    const [loaded, setloaded] = useState(false);
    useEffect(() => {
        // console.log(station)
        // console.log(rotation)
        if (station && rotation) {
            setloaded(true)
        }
    }, [station, rotation]);

    if (!loaded) {
        return (<div>Loading...</div>)
    }
  return (
            <table key={station.name+"table"} className="table-auto rounded-t-xl overflow-hidden bg-gradient-to-r from-gray-50 to-gray-100 p-10">
                <thead>
                    <tr key={station.name+'station_name'}>
                        <th className='px-4 py-2 text-gray-200 bg-gray-500 border-gray-500'>
                            <div>
                                {station.name}
                            </div>
                            <div className='italic text-xs'>
                                {station.location}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rotation.map((group, index) => {
                        // console.log(index, rotation.length)
                        // const { position, ...station } = station;
                        //return <RotationResultStation station={station} rotation={rotation}/>
                        
                        return (
                            <tr key={group.name+index+"rotation"}>
                                <td className={`border border-gray-500 px-4 py-2  font-medium 
                                ${group.break?"crossed text-gray-400"
                                :"text-gray-600"}`} >
                                    {group.name}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
                    
           /* <>
                <div>
                    {element.name}
                </div>
                {element.rotation.map((rotation, index) => {
                    console.log(index, element.rotation.length)
                    return rotation.name + (index < element.rotation.length-1 ?  " -> ":"")
                })}
            </> */
            
  )
}
