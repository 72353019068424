import React, {useEffect, useState} from 'react'

export default function RotationResultGroup({group, rotation}) {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        // console.log(station)
        // console.log(rotation)
        if (group && rotation) {
            setLoaded(true)
        }
    }, [group, rotation]);

    if (!loaded) {
        return (<div>Loading...</div>)
    }
  return (
            <div className='rotation-result inline-block m-6'>
                <ul>                        
                    <li key={group.name+"group"} className='no-after'>
                        <span className='no-after'>
                            {group.name}
                        </span>
                    </li>
                    {rotation.map((rotation, index)=>{
                        return <li key={group.name+index+"rotation"}>
                            <span className={index !== 0? 'station-of-group': ""}>
                                {rotation.name} 
                                
                                {rotation.location&&
                                    <em> ({rotation.location})</em>
                                }
                                
                            </span>
                            </li>
                    })}
                </ul>
           </div>
  )
}
