import React, { useEffect } from 'react'
import { useList } from "@uidotdev/usehooks";


export default function ListOf({ItemType, setListCallback, defaultListValues}) {
    const [items, { set, push, removeAt, insertAt, updateAt, clear }] = useList(
        []
      );
      useEffect(() => {
        set(defaultListValues);
      },[]);
      
      useEffect(() => {
        setListCallback(items);
      },[items, setListCallback]);
  return (
    <>
        {items.map((item, index) => {
            // console.log(index, item, ItemType.name)
            return(
                <div  key={"" + index + item.name + ItemType.name}>
                    <ItemType 
                        item={item} 
                        deleteCallback={()=> removeAt(index)}
                        editCallback={(item)=> updateAt(index, item)}
                        />                    
                </div>
            ) 
        })}
        <button 
            className='bg-gray-700 hover:bg-gray-900 text-gray-200 pt-2 pb-2 h-10'
            onClick={()=>push({name:items.length +1})}
            >Add</button>


    </>
    
  )
}
