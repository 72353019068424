import React, { useState,useEffect } from 'react'
import StationListItem from '../Components/StationListItem'
import { useList } from "@uidotdev/usehooks";
import ListOf from '../Components/ListOf';
import GroupListItem from '../Components/GroupListItem';
import RotationResult from '../Components/RotationResult/RotationResult';
import Footer from '../Components/Footer';

export default function RotationPage() {
    const defStation=[
        {
            name: "Station 1",
            location: "Tree"
        },
        {
            name: "Station 2",
            location: "Forest"
        },
        {
            name: "Station 3",
            location: ""
        },
        {
            name: "Station 4",
            location: ""
        }
    ]
    const defGroups=[
        {name: "Group 1"},
        {name: "Group 2"},
        {name: "Group 3"}
    ]
    const [
        stationList, 
        { 
            set : stationListSet, 
            push : stationListPush, 
            removeAt : stationListRemoveAt, 
            insertAt : stationListInsertAt, 
            updateAt : stationListUpdateAt, 
            clear : stationListClear 
        }
    ] = useList([]);
    const [
        groupList, 
        { 
            set : groupListSet, 
            push : groupListPush, 
            removeAt : groupListRemoveAt, 
            insertAt : groupListInsertAt, 
            updateAt : groupListUpdateAt, 
            clear : groupListClear 
        }
    ] = useList([]);






  return (
    <>
        <div className=" bg-gray-900 text-gray-200 pb-2 pt-1 flex flex-grow justify-center items-center">
            <div className='pl-3 '>v0.0.1</div>
            <div className='text-3xl font-bold flex-auto text-center'>
                Rotation Page
            </div>
            <button className='pr-3' onClick={()=>alert("Учи английский!")}>
                EN
            </button>
        </div>
        <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-8 pl-8 pr-8 pt-4'>
            <div className='flex flex-col gap-1'>
                <div className='font-bold bg-gray-500 text-gray-200 pt-2 pb-2 text-lg text-center'>
                    Stations
                </div>
                <ListOf ItemType={StationListItem} setListCallback={stationListSet} defaultListValues={defStation}/>

            </div>
            <div className='flex flex-col gap-1 text-center'>
                <div className='font-bold bg-gray-500 text-gray-200 pt-2 pb-2 text-lg'>
                    Groups
                </div>
                <ListOf ItemType={GroupListItem} setListCallback={groupListSet} defaultListValues={defGroups}/>
            </div>
        </div>
        
        <div>
            <RotationResult groupList={groupList} stationList={stationList}/>
        </div>
        <Footer/>
    </>
  )
}
