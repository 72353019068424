import React, {useState, useEffect, useRef} from 'react'
import { useList } from "@uidotdev/usehooks";
import RotationResultStation from './RotationResultStation';
import RotationResultGroup from './RotationResultGroup';
import { useReactToPrint } from 'react-to-print';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faImages } from '@fortawesome/free-solid-svg-icons';
import * as htmlToImage from 'html-to-image';


export default function RotationResult({groupList, stationList}) {
    const [canCalculate, setCanCalculate] = useState(false);
    const [calculated, setCalculated] = useState(false);
    const [printing, setPrinting] = useState(false);
    const groupsRef = useRef();
    const stationRef = useRef();


    const [
        groupRotationList, 
        { 
            set : groupRotationListSet, 
            push : groupRotationListPush, 
            removeAt : groupRotationListRemoveAt, 
            insertAt : groupRotationListInsertAt, 
            updateAt : groupRotationListUpdateAt, 
            clear : groupRotationListClear 
        }
    ] = useList([]);
    const [
        stationRotationList, 
        { 
            set : stationRotationListSet, 
            push : stationRotationListPush, 
            removeAt : stationRotationListRemoveAt, 
            insertAt : stationRotationListInsertAt, 
            updateAt : stationRotationListUpdateAt, 
            clear : stationRotationListClear 
        }
    ] = useList([]);
    const calculateRotation = () =>{
        // console.log("groupList.length", groupList.length)
        // console.log("stationList.length", stationList.length)
        console.log("start")

        const groupAmount = groupList.length;
        const stationAmount = stationList.length;
        
        setCalculated(false)

        if(groupAmount > stationAmount || stationAmount === 0){
            setCanCalculate(false)
            return;
        }
        setCanCalculate(true)

        /// STATION ROTATION
        // Main Logic
        const mainGaps = Math.floor(stationAmount/groupAmount - 1)
        const additionalGaps = stationAmount % groupAmount;

        // Generate rotation for first Station
        const firstStation = stationList[0];
        let firstStationRotation = [];
        const brakeElement = {name:"BREAK", break:true}
        console.log("start", firstStation)
        groupList.forEach((group, index) => {
            firstStationRotation.push(group);
            //Adding main gaps
            for (let i = 0; i < mainGaps; i++) {
                console.log(`gaps ${i}, mainGaps ${mainGaps}`)
                firstStationRotation.push(brakeElement);
            }
            //Adding additional gaps
            if (index < additionalGaps) {
                firstStationRotation.push(brakeElement);
            }
            
        });
        stationList.forEach((station,index) => {
            // const rotation = firstStationRotation.concat(firstStationRotation.splice(0, firstStationRotation.length - index));
            let rotation = [...firstStationRotation]
            rotation = rotation.concat(rotation.splice(0,rotation.length-index)); 
            // rotation = rotation.concat(rotation.splice(0,index));
            // console.log(`index`, index)
            // console.log(`firstStationRotation`, firstStationRotation)
            // console.log(`rotation`, rotation)
            stationRotationListPush({...station, rotation: rotation})
        });
        
        console.log(firstStationRotation)
        console.log(stationRotationList)
        //a = a.concat(a.splice(0, a.length - k));


        // calculateGroupRotation();

        setCalculated(true)
    }
    const calculateGroupRotation = () =>{
        groupRotationListClear()
        /// GROUP ROTATION
        groupList.forEach(group => {
            console.log("GROUP ROTATION", group)
            console.log("GROUP ROTATION", group)
            const groupRotation = Array(stationRotationList.length);
            stationRotationList.forEach(stationRotation => {
                const { rotation, ...station } = stationRotation;
                stationRotation.rotation.forEach((stationRotation, index) => {
                    if(stationRotation.name === group.name){
                        groupRotation[index] = station
                    }
                });
            });
            groupRotationListPush({...group, rotation:groupRotation})
            console.log(`Rotation for ${group.name}`,groupRotation)
        });
    }

    useEffect(() => {
        setCalculated(false)

        stationRotationListClear()
        groupRotationListClear()
        
        calculateRotation();
        
    }, [groupList, stationList]);

    useEffect(() => {
        calculateGroupRotation();
    }, [stationRotationList]);

    
    const handlePrintGroups = useReactToPrint({
        content: () => groupsRef.current,
      });
    const handlePrintStations = useReactToPrint({
        content: () => stationRef.current,
    });

    const downloadStationImage = async () => {
        setPrinting(true)
        const dataUrl = await htmlToImage.toPng(stationRef.current);
       
        // download image
        const link = document.createElement('a');
        link.download = `degesh.com_Stations_${(new Date().toJSON().slice(0,10))}.png`;
        link.href = dataUrl;
        link.click();
        setPrinting(false)
      }
      const downloadGroupImage = async () => {
        setPrinting(true)
        const dataUrl = await htmlToImage.toPng(groupsRef.current);
       
        // download image
        const link = document.createElement('a');
        link.download = `degesh.com_Groups_${(new Date().toJSON().slice(0,10))}.png`;
        link.href = dataUrl;
        link.click();
        setPrinting(false)
      }
      
      
  return (
    <div>        
        {!canCalculate && 
        <div>Can't calculate Rotation</div>
    }
    {canCalculate && !calculated &&
        <div>Calculating...</div>
    }
    {canCalculate && calculated &&
        <>
            <div ref={stationRef}>
                <div  className=' bg-gray-700 text-gray-200 pb-2 pt-1 flex flex-grow justify-center items-center mt-6'>
                    Rotation for Stations
                    <FontAwesomeIcon 
                        onClick={handlePrintStations} 
                        icon={faPrint} 
                        className='hover:text-gray-400 ml-4'
                        />
                    <FontAwesomeIcon 
                        onClick={downloadStationImage} 
                        icon={faImages} 
                        className='hover:text-gray-400 ml-4'
                        />
                    {printing && <span className='ml-6'>Made with Rotation.Degesh.com (Konstantin Davidenko)</span>}
                </div>
                
                <div className='grid md:grid-cols-4 md:gap-4 md:m-10 grid-cols-1 gap-1 m-6 print:grid-cols-4 '>
                    {stationRotationList.map((stationRotation, index)=>{
                        const { rotation, ...station } = stationRotation;
                        return <RotationResultStation key={station.name+index+"rotation_station"} station={station} rotation={stationRotation.rotation}/>
                        
                    })}
                </div>
                <Footer className="hidden print:block print:pl-40"/>
                
            </div>

            <div ref={groupsRef} >    
                <div className=' bg-gray-700 text-gray-200 pb-2 pt-1 flex flex-grow justify-center items-center mt-6'>
                    Rotation for Groups 
                    <FontAwesomeIcon 
                        onClick={handlePrintGroups} 
                        icon={faPrint} 
                        className='hover:text-gray-400 ml-4'
                        />
                    <FontAwesomeIcon 
                        onClick={downloadGroupImage} 
                        icon={faImages} 
                        className='hover:text-gray-400 ml-4'
                        />
                        {printing && <span className='ml-6'>Made with Rotation.Degesh.com (Konstantin Davidenko)</span>}
                </div>
                
                <div>
                    {groupRotationList.map((groupRotation, index)=>{
                        const { rotation, ...group } = groupRotation;
                        return <RotationResultGroup key={group.name+index+"rotation_station"} group={group} rotation={groupRotation.rotation}/>
                        
                    })}
                </div>
                
                <Footer className="hidden print:block print:pl-40"/>
            </div>
        </>
        }
        </div>
  )
}
